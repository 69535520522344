@import '../../base.scss';

@keyframes pulse {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 20;
    position: absolute;
    top: 0px; 
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 20px 10px;
}

.info{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

///логотип

.box_logo{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}


.logo_link{
    box-sizing: content-box;
    display: flex;
    align-items: center;
    width: 250px;
    margin-top: -10px;
}

.logo{
    width: 250px;
    height: auto;
}

//// телефон и соцсети

.contacts{
    max-width: 320px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box_phone{
    max-width: 220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.phone{
    font-size: 25px;
    line-height: 1;
    font-weight: 400;
    margin: 0 0 5px;
    text-decoration: none;
    color: $white;
    vertical-align: bottom;

    &:hover{
        cursor: pointer;
        opacity: .5;
        transition: .3s;
    }
}

.call {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    background-color: transparent;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    right: 0;
    top: -16px;
}

.call_pulse {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #4CBD2F;
    animation: pulse 1s infinite linear;
}

.call_text {
    color: #C4C4C4;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    margin: 0;
}

@media(max-width: 768px){

    .box{
        padding: 20px 10px 5px;
        align-items: center;
    }

    .box_logo{
        padding-bottom: 0;
    }

    .logo_link{
        width: 200px;
    }
    
    .logo{
        width: 200px;
    }

    
    .item_phone{
        display: block;
        box-sizing: content-box;
    }

    .contacts{
        max-width: 180px;
        margin-left: 0px;
    }

    .box_social {
        display: none;
    }

    .phone{
        font-size: 20px;
    }
}

@media(max-width: 425px){

    .logo_link{
        width: 130px;
    }
    
    .logo{
        width: 130px;
    }


    .contacts{
        max-width: 160px;
    }

    .phone{
        font-size: 18px;
    }

    .call_text {
        font-size: 11px;
    }
}