@import '../../base.scss';

.list{
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.item{
    box-sizing: content-box;
}

.link_telegram{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(293.89deg, #0782BE 35.44%, #7BCEF8 94.34%);

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.link{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(293.89deg, #008F00 35.44%, #0AC143 94.34%);

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 20px;
    height: 20px;
}