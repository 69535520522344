@import '../../base.scss';

@keyframes wheel-rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title
}

.list{
    max-width: 1440px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: 1fr;
    margin-bottom: 30px;
}

.button{
    @include button;
}