@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
    padding: 30px 20px;
}

.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 10px;
    overflow: hidden;
}

.cell{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,.6);
    backdrop-filter: blur(57px);
    overflow: hidden;
    padding-bottom: 10px;

    &:hover{
        cursor: pointer;
        transform:scale(1.02);
        transition: .5s;
    }
}

.cell_image{
    width: 100%;
    border-radius: 8px;
}

.cell_info{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.cell_subtitle{
    font-size: 20px;
    line-height: 1.2;
    margin: 0 0 10px;
    text-align: center;
    max-width: 300px;
}

.cell_text{
    max-width: 320px;
    width: 100%;
    color: $text;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    height: 96px;
}

.cell_button{
    @include button;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

@media(max-width: 1350px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media(max-width: 1280px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}


@media(max-width: 900px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
}

@media(max-width: 768px){
    .box{
        padding: 10px;
    }

    .list{
        gap: 10px;
    }

    .cell_text{
        font-size: 14px;
    }
}

@media(max-width: 425px){
    .box{
        padding: 10px;
    }

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}