@import '../../base.scss';

.main{
    @include main-column; 
    background-color: $background-footer;
    padding: 50px 20px 100px;
}

.container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.box{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo{
    width: 250px;
    height: auto;
    margin-bottom: 20px
}

.box_address{
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    max-width: 400px;
}

.icon_address{
    width: 30px;
    height: 25px;
    margin-right: 10px;
}

.box_mail{
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.icon_mail{
    width: 30px;
    height: 25px;
    margin-right: 10px;
}

.text{
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    width: 100%;
    margin: 0;
    text-align: left;
    color: $white;
}

.text_info{
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
    width: 100%;
    margin: 0;
    text-align: left;
    color: $white;
}

.link_mail{
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    margin: 0;
    text-decoration: none;
    color: $white;
    vertical-align: bottom;

    &:hover{
        cursor: pointer;
        opacity: .5;
        transition: .3s;
    }
}

.link_developer{
    text-decoration: none;
    color: $white;
    & :hover{
        opacity: .5;
        transition: .3s;
    }
    & span{
        color: $red;
        font-weight: 700;
    }
}

.list{
    max-width: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item{
    box-sizing: content-box;
}

.link_telegram{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.link{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 30px;
    height: 30px;
}

@media(max-width: 1028px){

    .box{
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media(max-width: 768px){

    .main{
        padding: 30px 20px 100px;
    }

    .container{
        flex-direction: column;
        align-items: flex-start;
    }

    .box{
        max-width: 100%;
        margin-bottom: 10px;
    }

    .list{
        margin: 10px 0px;
    }

    .logo{
        width: 150px;
    }
}

@media(max-width: 425px){
    
    .text{
        font-size: 14px;
        line-height: 24px;
    }
}