@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
    padding: 30px 20px;
}

.list {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
    border-radius: 8px;
}

.text {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;

    & span {
        color: #7BCEF8;
    }

}

.price {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    color: $blue;
    margin: 0;
}

@media(max-width: 768px){
    .text {
        font-size: 16px;
    
    }
    
    .price {
        font-size: 16px;
    }
}

@media(max-width: 425px){
    .item {
        padding: 10px;
        gap: 30px;
        margin-bottom: 10px;
    }

    .text {
        font-size: 14px;
    
    }
    
    .price {
        font-size: 14px;
    }
}