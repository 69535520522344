@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    padding: 50px 20px;
}

.box_info{
    box-sizing: border-box;
}


.title{
    @include title;
    text-align: left;
}

.text{
    color: $text;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0 0; 
}

.image{
    width: 600px;
    aspect-ratio: 4/3;
    border-radius: 6px;
    float:left;
    margin: 0 20px 0 0;
}

.box_link {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.link {
    @include button;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


@media(max-width: 1100px){
    .image{
        width: 45%;
    }

    .title{
        font-size: 30px;
    }
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;   
        padding: 40px 10px;
    }

    .image{
        width: 100%;
        margin: 0 0 20px;
    }
    
    .box_info{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }

    .title{
        text-align: center;
    }

    .text{
        font-size: 16px;
    }
}

@media(max-width: 500px){
    .title{
        font-size: 28px;
    }
}

@media(max-width: 425px){
    .title{
        font-size: 26px;
    }
}


@media(max-width: 425px){

    .container{
    padding: 30px 10px;
    }
}